import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import SnipcartButton from "./SnipcartButton";

const BackgroundColor = styled.article`
  background: radial-gradient(${(props) => props.bg1}, ${(props) => props.bg2});
`;

export default function ProductCard({ product }) {
  const [currentQty, setCurrentQty] = useState();

  // const fetchAndUpdateQuantity = () => {
  //   var requestOptions = {
  //     method: "GET",
  //     redirect: "follow",
  //   };

  //   fetch(
  //     `${process.env.GATSBY_SITE_URL}/wp-json/wp/v2/products/${product.databaseId}`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => setCurrentQty(result["acf"]["in_stock"]))
  //     .catch((error) => console.log("error", error));
  // };

  // useEffect(() => {
  //   fetchAndUpdateQuantity();
  //   const interval = setInterval(fetchAndUpdateQuantity, 1000 * 60 * 5);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // });

  return (
    <BackgroundColor
      className="products"
      bg1={product.acfDrinkInfo.colour}
      bg2={product?.acfDrinkInfo?.colourDark}
    >
      {product?.acfBackgroundArt?.backgroundArt ? (
        <GatsbyImage
          className="backgroundArt"
          objectFit="cover"
          image={
            product?.acfBackgroundArt?.backgroundArt?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
        />
      ) : (
        ""
      )}
      <Link to={product?.uri}>
        {currentQty < 50 && currentQty !== null && currentQty > 0 ? (
          <label className="low-stock tag-label">Low stock... Order now!</label>
        ) : (
          ""
        )}

        <GatsbyImage
          className="productImage"
          objectFit="contain"
          image={
            product?.acfCustomThumbnail?.customThumbnail?.localFile
              ?.childImageSharp?.gatsbyImageData
          }
        />

        <div className="beers_rollover">
          <h2 className="beers_rollover__heading heading heading--medium">
            {product?.title}
          </h2>
          <div className="beers__short-desc text text--small">
            {product?.acfDrinkInfo?.shortDescription}
          </div>
        </div>
      </Link>
    </BackgroundColor>
  );
}
