import React from 'react'
import { graphql } from 'gatsby'
import ProductArchiveHero from '../components/reusable/ProductArchiveHero'
import ProductCard from '../components/reusable/ProductCard'
import Seo from '../components/global/Seo'

export default function ProductArchive(props) {
  const page = props?.data?.page?.acfProductCategoryArchivePage
  const products = props?.data?.products?.nodes
  
  // if(products[0].menuOrder !== null){
  //   products.unshift(products.pop())
  // }

  return (
   <>
      <Seo seo={props?.data?.page.seo} />
      <ProductArchiveHero page={page} slug={props?.pageContext?.slug} />
      <div id="products" className={`product-archive product-archive-${props?.pageContext?.slug}`}>
        {
          products?.map((product, index) => <ProductCard product={product} key={`product_${index}`} />)
        }
        <div className="heading more_coming">New products coming soon...</div>
      </div>
   </>
  )
}

export const productArchiveQuery = graphql`
  query productArchiveQuery($slug: String) {
    page: wpPage(slug: {eq: $slug}) {
      seo{
        metaDesc
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphTitle
        title
        opengraphImage {
          publicUrl
        }
        twitterImage {
          publicUrl
        }
      }
      acfProductCategoryArchivePage {
        backgroundColour
        buttonText
        heading
        introText
        imageLeft {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
        imageRight{
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
        logo{
          localFile {
            publicURL
          }
        }
      }
    }
    products: allWpProduct(
    filter: {productCategories: {nodes: {elemMatch: {slug: {eq: $slug}}}}}
    sort: {fields: menuOrder, order: ASC}
    ) {
      nodes {
        title
        uri
        menuOrder
        databaseId
        acfDrinkInfo {
          shortDescription
          abv
          colour
          colourDark
        }
        acfBackgroundArt {
          backgroundArt {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        acfCustomThumbnail {
          customThumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`