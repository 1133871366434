import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import { Link } from "gatsby";

const BackgroundColour = styled.div`
  background: ${(props) => props.bg};
`;
export default function ProductArchiveHero({ page, slug }) {
  const handleClick = (e) => {
    e.preventDefault();

    const element = document.querySelector(e.target.hash);
    const headerHeight = document.querySelector(".site-head").offsetHeight;
    const topPos =
      element.getBoundingClientRect().top + window.pageYOffset - headerHeight;

    window.scrollTo({
      top: topPos, // scroll so that the element is at the top of the view
      behavior: "smooth", // smooth scroll
    });

    // window.scrollTop(e.target)
  };

  return (
    <BackgroundColour
      className={`product-archive-hero  ${slug}`}
      bg={page?.backgroundColour}
    >
      <div className={`wrap`}>
        <img className="logo" src={page?.logo?.localFile?.publicURL} />
        <h1 className="heading heading--x-large">{page?.heading}</h1>
        <div className="text white">{parse(page.introText)}</div>
        {slug === "hbco-gin" ? (
          <Link to="/latest-news/we-made-a-gin/" className="btn btn--white">
            {page?.buttonText}
          </Link>
        ) : (
          <a
            href="#products"
            onClick={(e) => handleClick(e)}
            className="btn btn--white"
          >
            {page?.buttonText}
          </a>
        )}
      </div>
      {page?.imageLeft?.localFile?.publicURL &&
      page?.imageLeft?.localFile?.childImageSharp === null ? (
        <img
          className="img-left"
          src={page?.imageLeft?.localFile?.publicURL}
          alt=""
        />
      ) : (
        ""
      )}
      {page?.imageRight?.localFile?.publicURL &&
      page?.imageRight?.localFile?.childImageSharp === null ? (
        <img
          className="img-right"
          src={page?.imageRight?.localFile?.publicURL}
          alt=""
        />
      ) : (
        ""
      )}
      {page?.imageLeft?.localFile?.childImageSharp !== null ? (
        <GatsbyImage
          className="img-left"
          image={page?.imageLeft?.localFile?.childImageSharp?.gatsbyImageData}
          alt=""
        />
      ) : (
        ""
      )}
      {page?.imageRight?.localFile?.childImageSharp !== null ? (
        <GatsbyImage
          className="img-right"
          image={page?.imageRight?.localFile?.childImageSharp?.gatsbyImageData}
          alt=""
        />
      ) : (
        ""
      )}
    </BackgroundColour>
  );
}
